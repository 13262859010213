.jumbo {

	padding: 3em 0;
	background: theme(secondary, base);
	color: $white;
	text-align: center;

}

	.marketing_title {

		h1 {
			margin: 0;
			&:before { content: "Need a "; }
			&:after { content: "?"; }
		}
		
		p {
			margin: 1em 0 1em;
			font-size: 1.3rem;
			font-weight: 600;
		}
		
		@include media-breakpoint-up(md) {
			h1 { font-size: 2.1rem; }
			p { font-size: 1.6rem; }
		}
		
		@include media-breakpoint-up(lg) {
			h1 { font-size: 2.3rem; }
			p { font-size: 1.8rem; }
		}
		
	}

	.marketing_text {
		
		ul {
			margin: 0;
			font-size: 1.2rem;
			li {
				margin-bottom: 0.25em !important;
				i {
					display: inline-block;
					width: 1em;
					height: 1em;
					margin-right: 0.125em;
					vertical-align: bottom;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: left center;
					background-image: url("/_/images/icons/cm_accent.svg");
				}
			}
		}

		@include media-breakpoint-up(md) {
			ul {
				font-size: 1.3rem;
				li { margin-bottom: 0.5em !important; }
			}
		}
		
		@include media-breakpoint-up(lg) {
			ul { font-size: 1.5rem }
		}
		
		@include media-breakpoint-up(xl) {}	

	}

	.marketing_convert {
		.btn {
			margin: 1em auto 0;
			font-size: 1.2rem;
			font-weight: 800;
			letter-spacing: -0.0125em;
			text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
		}
	}


