$form_front_bg: 				$white;
$form_front_border: 			1px solid #ddd;
$form_front_title_bg: 			theme(primary, base);
$form_front_title_color: 		$white;


.form_front {

	.contractor_list & {
		background-color: $color-highlight;
		padding: 15px;
		.form_title {
			margin-left: 0;
			margin-right: 0;
		}
	}

	color: $white;

	.form_section {
		legend {
			display: block;
			width: 100%;
			padding-bottom: 0.5em;
			margin-bottom: 1em;
			border-bottom: 3px solid theme(highlight, dark);
		}
	}
	
	.section_security {
		padding-top: 1em;
		border-top: 3px solid theme(highlight, dark);
	}

	.form_group { padding: 0; }

	.form_service_type {
		input {
			display: inline-block;
			position: relative;
			top: 0.3em;
			width: 1rem;
			height: 1rem;
			vertical-align: top;
		}
		label {
			display: block;
			font-size: 1rem;
			font-weight: 600;
		}
	}

	textarea.form_control {
		height: 150px;
	}
	
	.error-message {
		border-top: 3px solid theme(highlight, base);
		background-color: rgba($white,0.6);
		color: theme(danger, base);
	}

	@include media-breakpoint-up(sm) {
		.form_service_type label {
			display: inline-block;
			margin-right: 1em;
		}
	}

	.full_form & {
		@include media-breakpoint-only(md) {
			.form_group { margin: 0 0 0.75em; }
				.form_control { padding: 14px 9px }
				select.form_control { padding: 9px 7px }
			.form_service_type label {
				display: block;
				margin-right: 0;
			}
		}
		@include media-breakpoint-up(md) {
			.section_info,
			.section_help { @include make-col(6); }
			.section_info { padding-right: 15px; }
			.section_help { padding-left: 15px; }
			.form_spam { float: left; }
			.form_submit { float: left; padding: 12px 0 0 15px }
		}
		@include media-breakpoint-up(lg) {
			.form_submit { padding-top: 8px; }
			.form_control { padding: 10px 9px }
			select.form_control { padding: 8px 7px }		
		}
	}

	.sidebar & {
		padding: 15px 15px 0;
		background-color: $color-highlight;
		.form_title {
			margin: 0 0 1em;
		}
		@include media-breakpoint-only(lg) {
			.form_spam {
				height: 70px;
				.g-recaptcha {
					transform: scale(0.87);
					left: -18px;
					top: -12px;
				}
			}
		}
	}

}
