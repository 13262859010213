.nav_bar {

	.nav_menu > .menu_item {
		display: block;
		float: left;
		width: percentage(1/7);
		font-size: 14px;
		font-weight: 800;
		text-transform: uppercase;
		text-align: center;
	}

		.dropdown_menu {
			position: absolute;
			z-index: 99000;
			text-transform: none;
			font-weight: 400;
		}	

}