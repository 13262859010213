$mobile-button-bg: 			$color-secondary;
$toggle-bg: 				$color-secondary;
$toggle-color: 				$white;
$toggle-hover-bg: 			$color-accent;

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}

.mobile_button {

	padding: 0 20px;
	background: $mobile-button-bg;

	.mobile_toggle {
		padding: 0.75em;
		background-color: $toggle-bg;
		border: none;
		color: $toggle-color;
		&:hover, &:focus {
			outline: none;
			background-color: $toggle-hover-bg;
			cursor: pointer;
		}
	}

		.button-bars {
			display: inline-block;
			height: 100%;
			width: 1.5em;
			margin-right: 0.25em;
		}

			.icon-bar {
				display: block;
				width: 100%;
				height: 1px;
				background-color: $toggle-color;
				&:nth-child(2) { margin: 0.3em 0; }
			}

}



.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 90%;
	max-width: 320px;
	height: 100%;
	padding: 30px 15px;
	border-right: 3px solid $white;
	box-shadow: 2px 0 5px 0 rgba(0,0,0,0.5);
	background-color: $color-secondary;
	color: $white;

	@include media-breakpoint-up(md) { display: none !important; }

	h4 {
		font-size: 1.2em;
		font-weight: 800;
		text-transform: uppercase;
	}

	.nav_menu > .menu_item:not(:last-child) { border-bottom: 1px solid rgba($white,0.3); }
	//.nav_menu > .menu_item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.3); }

	.nav_menu > .menu_item {

		font-size: 0.95rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: -0.0325em;

		&.active .menu_link {
			background-color: $white;
			color: $color-accent;
			&:hover, &:focus {
				outline: none;
				background-color: $white;
				color: $color-accent;
			}
		}
		&.open {
			> .menu_link { background-color: rgba(255,255,255,0.2); }
			.dropdown_menu { background-color: rgba(255,255,255,0.2); }
		}

	}	

		.nav_menu > .menu_item > .menu_link {
			padding: 12px 10px;
			background-color: transparent;
			color: $white;
			&:hover, &:focus {
				outline: none;
				background-color: $color-accent; // rgba(255,255,255,0.2);
				color: $white;
			}
		}

	.dropdown_menu {

		.menu_item { font-size: 0.875em; }

			.menu_link {
				background-color: transparent;
				color: $white;
				&:hover, &:focus {
					outline: none;
					background-color: rgba(0,0,0,0.2);
					color: $white;					
				}				
			}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}



.navmenu {
	@include offcanvas;
	width: 290px; //$navmenu-width;
	height: 100%;
	border-width: 1px;
	border-style: solid;
	//border-radius: $border-radius-base;
}

.navmenu-fixed-left {
	position: fixed;
	z-index: 999999; //$zindex-navmenu-fixed;
	top: 0;
	left: 0;
	right: auto;
	border-width: 0 1px 0 0;
	bottom: 0;
	overflow-y: auto;
}




