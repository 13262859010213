.block-main {
	padding: 1.5em 0;
	clear: both;
	.block_title { margin-top: 0; }
}


.block.quote {

	background-color: theme(highlight, base);

	.block_title {
		font-weight: 800;
		color: $white;
		small {
			display: block;
			font-size: 72.5%;
			font-weight: 400;
			letter-spacing: 0.075em;
			//text-transform: uppercase;
		}
	}

}