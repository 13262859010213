$form_quote_bg: 				$white;
$form_quote_border: 			1px solid #ddd;
$form_quote_title_bg: 			theme(primary, base);
$form_quote_title_color: 		$white;


.form_quote {

	@extend %card;
	padding: 0 0 0.75rem;
	border: $form_quote_border;
	background-color: $form_quote_bg;

	.form_title {
		margin: 0 0 0.5rem;
		padding: 0.75rem; 
		background-color: $form_quote_title_bg;
		color: $form_quote_title_color;
	}

}
