.sidebar {

	[class*="img_group"] {
		> img, > a {
			display: block;
			max-width: 300px;
			margin: 0 auto 15px;
		}
	}

	.pgroup {
		a {
			padding: 15px;
			background-color: $white;
		}
	}

	@include media-breakpoint-only(sm) {
		.img_group--2 {
			overflow: hidden;
			margin-bottom: 15px;
			> img, > a {
				display: block;
				float: left;
				width: 49.5%;
				margin: 0;
				&:nth-child(even) { float: right; }
			}
		}
		.img_group--3 {
			overflow: hidden;
			margin-bottom: 15px;
			> img, > a {
				float: left;
				width: 32.5%;
				margin-right: 1.25%;
				&:nth-child(3n) { margin-right: 0;  }
			}
		}
	}

	@include media-breakpoint-only(md) {
		.img_group--2 {
			overflow: hidden;
			margin-bottom: 15px;
			> img, > a {
				display: block;
				float: left;
				width: 49.5%;
				margin: 0;
				&:nth-child(even) { float: right; }
			}
		}
		.img_group--3 {
			overflow: hidden;
			margin-bottom: 15px;
			> img, > a {
				float: left;
				width: 32.5%;
				margin-right: 1.25%;
				&:nth-child(3n) { margin-right: 0;  }
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 15px;
	}

	@include media-breakpoint-up(lg) {
		.form_submit input { display: block; width: 100%; }
	}

	@include media-breakpoint-only(lg) {
		.form_spam {
			position: relative;
			overflow: hidden;
			height: 75px;
			.g-recaptcha {
				@include transform(scale(0.85));
				position: absolute;
				top: 0;
				left: -8px;
			}
		}		
	}

	> * { margin-bottom: 15px; }

}