#top { outline: none !important; }

.page_front .content {
	padding: 2em 0;
}

.page_glossary {

	hr,
	hr:focus {
		margin: 2em 0;
    	border: 0;
    	height: 2px;
    	outline: none !important;
    	background-image: linear-gradient(to right, rgba($color-accent, 0) 0%, rgba($color-accent, 0.9) 35%, rgba($color-accent, 0.9) 65%, rgba($color-accent, 0));	
	}

	[href="#top"] {
		display: inline-block;
		padding: 0.25em 0.5em;
		font-size: 0.95rem;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid darken($color-highlight, 15%);
		border-radius: 4px;
		background-color: $color-highlight;
		color: $white;
		&:hover {
			background-color: lighten($color-highlight, 15%);
		}
	}

	h3 {
		padding: 0.325em 0.5em;
		font-weight: 800;
		background: linear-gradient(to right, $color-primary 0px, $color-primary 46px, transparent 46px);
		color: $white;
		@include media-breakpoint-up(md) {
			padding: 0.5em;
			background: linear-gradient(to right, $color-primary, $color-primary 10%, transparent 60%);
		}
	}

}