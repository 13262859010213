$banner-bg: 			$white !default;
$banner-color: 			$color-text;

.banner {
	height: 46px;
	margin: 30px 0 15px;
	padding: 0;
	border-bottom: 3px solid $white;
	background-color: theme(primary, base);
	overflow: visible;

	.branding_logo {
		width: 270px;
		margin-top: -19px;
	}

	@include media-breakpoint-up(sm) {
		height: 70px;
		margin: 45px 0 20px;
		.branding_logo {
			width: 419px;
			margin-top: -30px;
		}
	}

	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}

/* 	@include media-breakpoint-up(lg) {
		height: 48px;
		.branding_logo {
			margin-left: 0;
			border: 1px solid cyan;
			max-width: 290px;
			margin-top: -20px;
		}
	}
 */
}