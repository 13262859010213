.slider {

	padding: 0;
	border: 10px solid $white;
	border-left-width: 5px;
	border-right-width: 5px;

	img {
		border: 5px solid $white;
		border-top: none;
		border-bottom: none;
	}

}