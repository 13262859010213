.nav_list {

	.nav_menu {
		> .menu_item {
			margin-bottom: 0.5em;
			> .menu_link {
				padding: 0.75em;
			}
		}

	}

}

.nav_jump {

	> .menu_item {
		display: block;
		float: left;
		width: percentage(1/3);
		margin-bottom: 0.5em;
		padding: 0 0.25em;
	}

	> .menu_item > .menu_link {
		padding: 0.75em;
		font-weight: 800;
		text-align: center;
		background-color: $color-secondary;
		color: $white;
		&:hover {
			background-color: lighten($color-secondary, 15%);
			color: $white;
		}
		&.disabled {
			background-color: $gray-400;
			color: $color-text;
		}
	}

	@include media-breakpoint-up(sm) {
		> .menu_item { width: percentage(1/6) }
	}

	@include media-breakpoint-up(md) {
		> .menu_item { width: percentage(1/10) }
	}

	@include media-breakpoint-up(lg) {
		> .menu_item { width: percentage(1/13) }
	}

}

.city_list,
.city_menu {
	.nav_menu > .menu_item {

		float: left;
		width: 49%;
		&:nth-child(odd) { clear: both; }
		&:nth-child(even) { float: right; }

		> .menu_link {
			@include txt--sm;
			border: 1px solid $color-primary;
			background-color: darken($color-primary, 5%);
			color: #fff;
			&:hover {
				background-color: lighten($color-primary, 15%);
			}
		}

		> [href="#"] {
			border-color: #aaa;
			background-color: #ddd;
			color: $color-text; 
			&:hover {
				background-color: #ddd;
				cursor: default;
			}
		}

	}	
}

.city_list {

	margin-bottom: 1em;

	.nav_menu > .menu_item > .menu_link {
		font-size: 1.1rem;
		font-weight: 600;
		text-transform: uppercase;
	}

	@include media-breakpoint-up(lg) {
		.nav_menu > .menu_item {
			width: 32%;
			margin-right: 2%;
			&:nth-child(odd) { clear: none; }
			&:nth-child(even) { float: left; }			
			&:nth-child(3n) { float: right; margin-right: 0 }
			&:nth-child(3n-2) { clear: both; }
		}
	}

}


.city_menu {

	@include media-breakpoint-up(md) {
		.nav_menu { @include txt--center }
		.nav_menu > .menu_item {
			float: none;
			display: inline-block;
			width: auto;
			&:nth-child(odd) { clear: none; }
			&:nth-child(even) { float: none; }
		}
	}


}