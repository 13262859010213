@charset "UTF-8";
.form_quote, .widget {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #333; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.block {
  padding: 20px 0; }

.block--center .row {
  justify-content: center; }

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  .wrap[class*="col"] {
    padding: 0.5em; }
  .wrap.col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wrap.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .wrap.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .wrap.col-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .wrap.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .wrap.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .wrap.col-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .wrap.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .wrap.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .wrap.col-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .wrap.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .wrap.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  @media (min-width: 576px) {
    .wrap.col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .wrap.col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .wrap.col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .wrap.col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .wrap.col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .wrap.col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .wrap.col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .wrap.col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .wrap.col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .wrap.col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .wrap.col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .wrap.col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; } }
  @media (min-width: 768px) {
    .wrap.col-md-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .wrap.col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .wrap.col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .wrap.col-md-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .wrap.col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .wrap.col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .wrap.col-md-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .wrap.col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .wrap.col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .wrap.col-md-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .wrap.col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .wrap.col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; } }
  @media (min-width: 992px) {
    .wrap.col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .wrap.col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .wrap.col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .wrap.col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .wrap.col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .wrap.col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .wrap.col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .wrap.col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .wrap.col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .wrap.col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .wrap.col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .wrap.col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; } }
  @media (min-width: 1200px) {
    .wrap.col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .wrap.col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .wrap.col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .wrap.col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .wrap.col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .wrap.col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .wrap.col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .wrap.col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .wrap.col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .wrap.col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .wrap.col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .wrap.col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; } }

.banner {
  position: relative;
  z-index: 99000; }

.jumbo {
  position: relative;
  z-index: 70000; }
  @media (min-width: 768px) {
    .jumbo .wrapper .wrap {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .jumbo .wrapper .wrap {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; } }
  @media (min-width: 1200px) {
    .jumbo .wrapper .wrap {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .full_width .content .row, .full_width .page-title .row,
  .contractor_list .content .row,
  .contractor_list .page-title .row {
    justify-content: center; }
  .full_width .content .main,
  .full_width .page-title .wrap,
  .contractor_list .content .main,
  .contractor_list .page-title .wrap {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

/* 	.page_contractor {

		.page-title,
		.welcome {
			.row { justify-content: center; }
			@include media-breakpoint-up(lg) { .wrap { @include make-col(11) } }			
			@include media-breakpoint-up(xl) { .wrap { @include make-col(11) } }			
		}

		.content {
			.row { justify-content: center; }
			@include media-breakpoint-up(lg) {
				.main { @include make-col(7); }
				.sidebar { @include make-col(4); }
			}
 			@include media-breakpoint-up(xl) {
				.main { @include make-col(6); }
				.sidebar { @include make-col(4); }
			}
		}

	} */
html {
  font-size: 16px;
  line-height: 1.6em;
  -webkit-text-size-adjust: 100%; }

body {
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  @media (min-width: 768px) {
    body {
      font-size: 1.1rem; } }
  @media (min-width: 768px) {
    body {
      font-size: 1.2rem; } }

h1, h2, h3, h4, h5, h6 {
  margin: 1.5em 0 0.5em;
  font-weight: 400;
  line-height: 1.1em; }

.h_top {
  margin-top: 0; }

h6 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.0125em; }

h5 {
  font-size: 1.2rem; }

h4 {
  font-size: 1.4rem;
  letter-spacing: -0.015em; }

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.0175em; }

h2 {
  font-size: 1.8rem;
  letter-spacing: -0.02em; }

h1 {
  font-size: 2.0rem;
  font-weight: 800;
  letter-spacing: -0.02em; }

p {
  margin: 0 0 1em; }

.lead {
  font-size: 115%;
  line-height: 1.53em; }

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }

.b0 {
  font-weight: 400; }

b, strong, .b1 {
  font-weight: 600; }

.bolder, .strong, .b2 {
  font-weight: 800; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 0.5em 0.5em 0.5em 1em;
  font-size: 1.15rem;
  border-left: 0.5rem solid; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #333; }
  blockquote.bq--primary {
    border-color: #1b75bc; }
  blockquote.bq--secondary {
    border-color: #14426F; }
  blockquote.bq--highlight {
    border-color: #ef4136; }
  blockquote.bq--accent {
    border-color: #35bf53; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #ffc107; }
  blockquote.bq--info {
    border-color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #35bf53; }
  a:hover, a:focus {
    color: #248339;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #2a82d9; }

.phone {
  text-decoration: none;
  cursor: default;
  color: #14426F; }
  .phone:hover {
    text-decoration: none;
    color: #14426F;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #333; }
  .phone.mask:hover {
    outline: none;
    color: #333; }

.list_inline li:not(:last-child), .list_unstyled li:not(:last-child), .list_icon li:not(:last-child), .list li:not(:last-child) {
  margin-bottom: 0.25em; }

.list_inline, .list_unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list_inline li {
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0 !important; }

.list_buttons a {
  border-radius: 3px; }

.list_icon {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none; }
  .list_icon li {
    padding-left: 2em;
    font-size: 1.125rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left center; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

.icon_check-highlight li {
  background-image: url("/_/images/icons/cm_highlight.svg"); }

.icon_check-accent li {
  background-image: url("/_/images/icons/cm_accent.svg"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.svg"); }

.icon_check-danger li {
  background-image: url("/_/images/icons/cm_danger.svg"); }

.icon_check-warning li {
  background-image: url("/_/images/icons/cm_warning.svg"); }

.icon_check-info li {
  background-image: url("/_/images/icons/cm_info.svg"); }

img {
  display: block;
  width: 100%;
  border-style: none; }

.img--caption span {
  display: block;
  margin: 5px 0 0;
  line-height: 1.1em; }

.img--main {
  margin: 0 0 15px;
  max-width: 360px; }

.img--thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 576px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 15px; } }

@media (min-width: 576px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 15px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  max-width: none; }

.img--coupon {
  max-width: 640px; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block; }

.mb0 {
  margin-bottom: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mt15 {
  margin-top: 15px !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase; }

.txt--cap {
  text-transform: capitalize; }

.txt--lg {
  font-size: 115%; }

.txt--md {
  font-size: 16; }

.txt--sm {
  font-size: 85%; }

.lh1 {
  line-height: 1.1em; }

.lh125 {
  line-height: 1.25em; }

.txt--left {
  text-align: left; }

.txt--right {
  text-align: right; }

.txt--center {
  text-align: center; }

.txt--wb0 {
  font-weight: 400; }

.txt--wb1 {
  font-weight: 600; }

.txt--wb2 {
  font-weight: 800; }

.color--text {
  color: #333; }

.color--white {
  color: #fff; }

.color--primary {
  color: #1b75bc; }

.color--secondary {
  color: #14426F; }

.color--highlight {
  color: #ef4136; }

.color--link {
  color: #35bf53; }

.color--review {
  color: #fd7e14; }

.color--success {
  color: #28a745; }

.color--danger {
  color: #dc3545; }

.color--warning {
  color: #ffc107; }

.color--info {
  color: #6610f2; }

.bg--text {
  background-color: #333; }

.bg--white {
  background-color: #fff; }

.bg--primary {
  background-color: #1b75bc; }

.bg--secondary {
  background-color: #14426F; }

.bg--highlight {
  background-color: #ef4136; }

.bg--link {
  background-color: #35bf53; }

.bg--review {
  background-color: #fd7e14; }

.bg--success {
  background-color: #28a745; }

.bg--danger {
  background-color: #dc3545; }

.bg--warning {
  background-color: #ffc107; }

.bg--info {
  background-color: #6610f2; }

.gdfancybg--text {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #333; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fff; }

.gdfancybg--primary {
  background: #1b75bc;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #1b75bc; }

.gdfancybg--secondary {
  background: #14426F;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #14426F; }

.gdfancybg--highlight {
  background: #ef4136;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #ef4136; }

.gdfancybg--accent, .page-title {
  background: #35bf53;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #35bf53; }

.gdfancybg--review {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fd7e14; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #ffc107; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.site_alert > .alert:first-of-type {
  margin-top: 1em; }

.site_alert > .alert:last-of-type {
  margin-bottom: 0; }

.alert {
  border: 1px solid #c4c4c4;
  background-color: white;
  color: #333;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 3px; }
  .alert strong, .alert a {
    color: #b7b7b7; }
  .alert strong {
    font-weight: 800; }

.alert-primary {
  border: 1px solid #15598f;
  background-color: #afd6f4;
  color: #333; }
  .alert-primary strong, .alert-primary a {
    color: #114b79; }

.alert-secondary {
  border: 1px solid #0c2844;
  background-color: #6ba8e4;
  color: #333; }
  .alert-secondary strong, .alert-secondary a {
    color: #081b2e; }

.alert-highlight {
  border: 1px solid #e01e12;
  background-color: #fef4f3;
  color: #333; }
  .alert-highlight strong, .alert-highlight a {
    color: #c91b10; }

.alert-accent {
  border: 1px solid #2a9742;
  background-color: #cef2d6;
  color: #333; }
  .alert-accent strong, .alert-accent a {
    color: #248339; }

.alert-success {
  border: 1px solid #1e7e34;
  background-color: #afecbd;
  color: #333; }
  .alert-success strong, .alert-success a {
    color: #19692c; }

.alert-danger {
  border: 1px solid #bd2130;
  background-color: #fae3e5;
  color: #333; }
  .alert-danger strong, .alert-danger a {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #510bc4;
  background-color: #e2d1fd;
  color: #333; }
  .alert-info strong, .alert-info a {
    color: #4709ac; }

.alert-warning {
  border: 1px solid #d39e00;
  background-color: #fff4d3;
  color: #333; }
  .alert-warning strong, .alert-warning a {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #2a9742;
  background-color: #cef2d6;
  color: #333; }
  .alert-link strong, .alert-link a {
    color: #248339; }

.announce {
  padding: 12px 0;
  border-bottom: 0.25rem solid #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #14426F;
  color: #fff; }
  .announce p {
    margin-bottom: 0.5em;
    font-weight: 800;
    text-transform: uppercase; }
    .announce p:last-child {
      margin-bottom: 0; }

[class*="announce_"] {
  text-align: center; }
  @media (min-width: 768px) {
    [class*="announce_"] p {
      float: left;
      margin: 0; } }

@media (min-width: 768px) {
  .announce_2 p {
    width: 50%; } }

@media (min-width: 768px) {
  .announce_3 p {
    width: 33.33333%; } }

@media (min-width: 768px) {
  .announce_4 p {
    width: 25%; } }

.banner {
  height: 46px;
  margin: 30px 0 15px;
  padding: 0;
  border-bottom: 3px solid #fff;
  background-color: #1b75bc;
  overflow: visible;
  /* 	@include media-breakpoint-up(lg) {
		height: 48px;
		.branding_logo {
			margin-left: 0;
			border: 1px solid cyan;
			max-width: 290px;
			margin-top: -20px;
		}
	}
 */ }
  .banner .branding_logo {
    width: 270px;
    margin-top: -19px; }
  @media (min-width: 576px) {
    .banner {
      height: 70px;
      margin: 45px 0 20px; }
      .banner .branding_logo {
        width: 419px;
        margin-top: -30px; } }
  @media (min-width: 768px) {
    .banner {
      margin-bottom: 0; } }

.branding_logo {
  display: block;
  width: 100%;
  margin: 0 auto;
  line-height: 0; }
  .branding_logo svg {
    margin: 0 !important; }
  .branding_logo svg .mark-border {
    fill: #1B75BC; }
  .branding_logo svg .mark-bg {
    fill: #14426F;
    -webkit-transition: all ease-in-out 400ms;
    -moz-transition: all ease-in-out 400ms;
    -ms-transition: all ease-in-out 400ms;
    -o-transition: all ease-in-out 400ms;
    transition: all ease-in-out 400ms; }
  .branding_logo svg .wrench-bg {
    fill: #1B75BC; }
  .branding_logo svg .wrench-outline {
    fill: #fff; }
  .branding_logo svg .plumbers {
    fill: #fff; }
  .branding_logo svg .net {
    fill: #14426F; }
  .branding_logo svg .bg {
    fill: #1B75BC; }
  .branding_logo:hover svg .mark-bg {
    fill: #35bf53;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.banner_menu {
  padding: 35px 0 0;
  background-color: #14426F;
  border-bottom: 5px solid #fff; }
  .banner_menu .nav_menu {
    text-align: center; }
    .banner_menu .nav_menu > .menu_item {
      display: inline-block; }
      .banner_menu .nav_menu > .menu_item > .menu_link:active, .banner_menu .nav_menu > .menu_item > .menu_link:link, .banner_menu .nav_menu > .menu_item > .menu_link:visited, .banner_menu .nav_menu > .menu_item > .menu_link:hover {
        padding: 15px;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        background-color: transparent;
        color: #fff; }
      .banner_menu .nav_menu > .menu_item > .menu_link:hover {
        background-color: #35bf53;
        color: #fff; }
      .banner_menu .nav_menu > .menu_item.active > .menu_link {
        background-color: #fff;
        color: #14426F; }
      .banner_menu .nav_menu > .menu_item a[href="#"]:hover {
        background-color: transparent !important;
        border-color: transparent !important;
        color: #fff !important;
        cursor: default !important; }
  @media (min-width: 992px) {
    .banner_menu .nav_menu > .menu_item {
      display: block;
      float: left;
      width: 14.28571%; } }

[class*="btn"] {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #959595;
  background-color: #bbb;
  color: #fff;
  border-radius: 3px;
  cursor: pointer; }
  [class*="btn"]:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #eee;
    color: #fff; }
  [class*="btn"]:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.list_buttons a, .btn_link {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #248339;
  background-color: #35bf53;
  color: #fff; }
  .list_buttons a:hover, .btn_link:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #4dce69;
    color: #fff; }

.btn_primary {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #114b79;
  background-color: #1b75bc;
  color: #fff; }
  .btn_primary:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #409be4;
    color: #fff; }

.btn_secondary {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #081b2e;
  background-color: #14426F;
  color: #fff; }
  .btn_secondary:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #2069b0;
    color: #fff; }

.btn_highlight {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #c91b10;
  background-color: #ef4136;
  color: #fff; }
  .btn_highlight:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #f26057;
    color: #fff; }

.btn_accent {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #248339;
  background-color: #35bf53;
  color: #fff; }
  .btn_accent:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #6bd682;
    color: #fff; }

.btn_success {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #19692c;
  background-color: #28a745;
  color: #fff; }
  .btn_success:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #48d368;
    color: #fff; }

.btn_danger {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff; }
  .btn_danger:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #e77681;
    color: #fff; }

.btn_info {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #4709ac;
  background-color: #6610f2;
  color: #fff; }
  .btn_info:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #9459f6;
    color: #fff; }

.btn_review {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #c35a02;
  background-color: #fd7e14;
  color: #fff; }
  .btn_review:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #fd933a;
    color: #fff; }

.btn_warning {
  -webkit-transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -ms-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #ba8b00;
  background-color: #ffc107;
  color: #fff; }
  .btn_warning:hover {
    -webkit-transition: all ease-in-out 100ms;
    -moz-transition: all ease-in-out 100ms;
    -ms-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    background-color: #ffd454;
    color: #fff; }

.btn-block {
  display: block;
  text-align: center; }

.btn_close {
  float: right;
  margin: 0 0 0 15px;
  padding: 5px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  opacity: 0.8;
  cursor: pointer; }
  .btn_close .close_img {
    width: 0.75rem; }
  .btn_close:hover {
    background-color: #921925; }

.list_buttons a, .btn--sm {
  padding: 0.2em 0.75em;
  font-size: 0.85rem; }

.btn--wide {
  display: block; }

.btn_3d {
  padding: 12px 1em;
  border-width: 1px;
  border-bottom-width: 6px; }
  .btn_3d:hover {
    padding: 15px 1em 14px;
    border-bottom-width: 1px; }

[class*="btn_ghost"] {
  border-color: #333;
  background: linear-gradient(to left, #fff 50%, #333 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #333;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  border-width: 2px; }
  [class*="btn_ghost"]:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-default {
  border-color: #35bf53;
  background: linear-gradient(to left, #fff 50%, #35bf53 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #35bf53;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-default:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-primary {
  border-color: #1b75bc;
  background: linear-gradient(to left, #fff 50%, #1b75bc 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #1b75bc;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-primary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-secondary {
  border-color: #14426F;
  background: linear-gradient(to left, #fff 50%, #14426F 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #14426F;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-secondary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-highlight {
  border-color: #ef4136;
  background: linear-gradient(to left, #fff 50%, #ef4136 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #ef4136;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-highlight:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-accent {
  border-color: #35bf53;
  background: linear-gradient(to left, #fff 50%, #35bf53 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #35bf53;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-accent:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-success {
  border-color: #28a745;
  background: linear-gradient(to left, #fff 50%, #28a745 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #28a745;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-success:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-danger {
  border-color: #dc3545;
  background: linear-gradient(to left, #fff 50%, #dc3545 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #dc3545;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-danger:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-info {
  border-color: #6610f2;
  background: linear-gradient(to left, #fff 50%, #6610f2 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #6610f2;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-info:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-review {
  border-color: #fd7e14;
  background: linear-gradient(to left, #fff 50%, #fd7e14 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #fd7e14;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-review:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-warning {
  border-color: #ffc107;
  background: linear-gradient(to left, #fff 50%, #ffc107 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #ffc107;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-warning:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.coverage {
  padding: 0.5em 0.5em 0.75em;
  border: 1px solid #ddd;
  background-color: #fff; }
  .coverage > *:first-child {
    margin-top: 0; }
  .coverage > *:last-child {
    margin-bottom: 0; }

.coverage_title {
  margin: 1em 0.5em 0.5em;
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase; }

.coverage_city {
  margin: 0 0.5em;
  font-size: 14px;
  line-height: 1.25em; }

.coverage_city + .coverage_city {
  margin-top: 0.5em; }

.form {
  font-size: 1em; }
  .form.form_career textarea {
    height: 180px; }

.form_section {
  box-shadow: none;
  border: none;
  margin: 0 0 1.25rem;
  padding: 0; }
  .form_section legend {
    display: block;
    margin: 0 0 0.5em;
    font-size: 1.25rem;
    font-weight: 800;
    font-style: italic;
    letter-spacing: -0.035em;
    text-transform: uppercase; }

.form_security {
  overflow: hidden;
  margin-bottom: 1em; }

.form_title {
  margin: 0 0.75rem 0.5rem;
  font-size: 1.4rem;
  font-weight: 800; }
  .form_title small {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: uppercase; }

.form_group {
  margin: 0 0 0.5em;
  padding: 0 0.75em; }
  .form_group.form_hp {
    display: none; }

.error-message {
  margin: 0;
  padding: 0.5em 0.5em 0.4em;
  font-size: 0.75em;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.015em;
  text-transform: uppercase; }

.form_control {
  display: block;
  width: 100%;
  padding: 9px 7px;
  font-size: 1rem;
  border: 1px solid #ddd;
  outline: none;
  background-color: #fff; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

select.form_control {
  padding: 7px; }

textarea.form_control {
  min-height: 95px; }

@media (max-width: 575px) {
  .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .form_spam .g-recaptcha {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
      position: absolute;
      top: 0;
      left: -18px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .fix-lg .form_spam {
    position: relative;
    overflow: hidden;
    height: 80px; }
    .fix-lg .form_spam .g-recaptcha {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);
      position: absolute;
      top: 0;
      left: -2px; } }

.form_submit {
  margin-top: 0.25rem; }

@media (min-width: 768px) {
  .form_lr .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr .form_left,
  .form_lr .form_right {
    width: 50%; }
  .form_lr .form_left {
    float: left;
    clear: both; }
  .form_lr .form_right {
    float: right; }
  .form_lr .form_clear {
    clear: both; }
  .form_lr .form_state {
    width: 20%;
    clear: none; }
  .form_lr .form_zip {
    width: 30%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .form_lr--sm .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr--sm .form_left,
  .form_lr--sm .form_right {
    width: 50%; }
  .form_lr--sm .form_left {
    float: left; }
  .form_lr--sm .form_right {
    float: right; }
  .form_lr--sm .form_clear {
    clear: both; } }

.form_front {
  color: #fff; }
  .contractor_list .form_front {
    background-color: #ef4136;
    padding: 15px; }
    .contractor_list .form_front .form_title {
      margin-left: 0;
      margin-right: 0; }
  .form_front .form_section legend {
    display: block;
    width: 100%;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    border-bottom: 3px solid #c91b10; }
  .form_front .section_security {
    padding-top: 1em;
    border-top: 3px solid #c91b10; }
  .form_front .form_group {
    padding: 0; }
  .form_front .form_service_type input {
    display: inline-block;
    position: relative;
    top: 0.3em;
    width: 1rem;
    height: 1rem;
    vertical-align: top; }
  .form_front .form_service_type label {
    display: block;
    font-size: 1rem;
    font-weight: 600; }
  .form_front textarea.form_control {
    height: 150px; }
  .form_front .error-message {
    border-top: 3px solid #ef4136;
    background-color: rgba(255, 255, 255, 0.6);
    color: #dc3545; }
  @media (min-width: 576px) {
    .form_front .form_service_type label {
      display: inline-block;
      margin-right: 1em; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .full_form .form_front .form_group {
      margin: 0 0 0.75em; }
    .full_form .form_front .form_control {
      padding: 14px 9px; }
    .full_form .form_front select.form_control {
      padding: 9px 7px; }
    .full_form .form_front .form_service_type label {
      display: block;
      margin-right: 0; } }
  @media (min-width: 768px) {
    .full_form .form_front .section_info,
    .full_form .form_front .section_help {
      flex: 0 0 50%;
      max-width: 50%; }
    .full_form .form_front .section_info {
      padding-right: 15px; }
    .full_form .form_front .section_help {
      padding-left: 15px; }
    .full_form .form_front .form_spam {
      float: left; }
    .full_form .form_front .form_submit {
      float: left;
      padding: 12px 0 0 15px; } }
  @media (min-width: 992px) {
    .full_form .form_front .form_submit {
      padding-top: 8px; }
    .full_form .form_front .form_control {
      padding: 10px 9px; }
    .full_form .form_front select.form_control {
      padding: 8px 7px; } }
  .sidebar .form_front {
    padding: 15px 15px 0;
    background-color: #ef4136; }
    .sidebar .form_front .form_title {
      margin: 0 0 1em; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .sidebar .form_front .form_spam {
        height: 70px; }
        .sidebar .form_front .form_spam .g-recaptcha {
          transform: scale(0.87);
          left: -18px;
          top: -12px; } }

.form_quote {
  padding: 0 0 0.75rem;
  border: 1px solid #ddd;
  background-color: #fff; }
  .form_quote .form_title {
    margin: 0 0 0.5rem;
    padding: 0.75rem;
    background-color: #1b75bc;
    color: #fff; }

.gallery {
  margin-bottom: 1em; }

.gallery_sidebar img {
  max-width: 360px;
  margin: 0 auto 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g2-md > img, .g2-md > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-md > img:nth-child(odd), .g2-md > a:nth-child(odd) {
      clear: both; }
    .g2-md > img:nth-child(even), .g2-md > a:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n) {
      float: right;
      margin-right: 0; } }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 95000;
  text-align: center;
  line-height: 0;
  font-weight: 400; }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 5px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto;
  border-radius: 3px 3px 0 0; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/layout/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/layout/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  color: #fff; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close-red.svg") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

body.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99000;
  cursor: pointer; }
  .modal.show {
    display: block; }

.modal-dialog {
  position: relative;
  z-index: 99500;
  width: 90%;
  max-width: 640px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: default; }
  .modal-dialog p {
    margin-bottom: 0;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 1.25em; }
    .modal-dialog p:nth-child(even) {
      background-color: #ddd; }

.modal-header {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc; }

.modal-title {
  margin: 15px 0 0;
  font-size: 18px; }

.nav_menu {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu_item.active .menu_link {
    cursor: default; }
    .menu_item.active .menu_link:hover {
      cursor: default; }
  .menu_item.open > .menu_link {
    background-color: #999;
    color: #333; }
  .menu_item.open .dropdown_menu {
    display: block;
    height: auto;
    background-color: #999; }
  .menu_item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu_link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }

.nav_menu > .menu_item > .menu_link {
  padding: 18px 10px;
  background-color: #ccc;
  color: #333; }
  .nav_menu > .menu_item > .menu_link:hover, .nav_menu > .menu_item > .menu_link:focus {
    background-color: #999;
    color: #333;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 0.85em;
  height: 0.85em;
  background-image: url("/_/images/icons/dropdown.svg");
  background-repeat: no-repeat;
  background-size: 0.85em;
  background-position: center center; }

.dropdown_menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  margin: 0;
  padding: 10px;
  min-width: 280px; }
  .dropdown_menu .menu_item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
    .dropdown_menu .menu_item:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
    .dropdown_menu .menu_item:not(:first-child) {
      border-top: 1px solid rgba(255, 255, 255, 0.7); }
  .dropdown_menu .menu_link {
    padding-left: 10px;
    background-color: #999;
    color: #333; }
    .dropdown_menu .menu_link:hover, .dropdown_menu .menu_link:focus {
      outline: none;
      background-color: rgba(255, 255, 255, 0.3);
      color: #333; }

.nav_bar .nav_menu > .menu_item {
  display: block;
  float: left;
  width: 14.28571%;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center; }

.nav_bar .dropdown_menu {
  position: absolute;
  z-index: 99000;
  text-transform: none;
  font-weight: 400; }

.nav_list .nav_menu > .menu_item {
  margin-bottom: 0.5em; }
  .nav_list .nav_menu > .menu_item > .menu_link {
    padding: 0.75em; }

.nav_jump > .menu_item {
  display: block;
  float: left;
  width: 33.33333%;
  margin-bottom: 0.5em;
  padding: 0 0.25em; }

.nav_jump > .menu_item > .menu_link {
  padding: 0.75em;
  font-weight: 800;
  text-align: center;
  background-color: #14426F;
  color: #fff; }
  .nav_jump > .menu_item > .menu_link:hover {
    background-color: #2069b0;
    color: #fff; }
  .nav_jump > .menu_item > .menu_link.disabled {
    background-color: #ced4da;
    color: #333; }

@media (min-width: 576px) {
  .nav_jump > .menu_item {
    width: 16.66667%; } }

@media (min-width: 768px) {
  .nav_jump > .menu_item {
    width: 10%; } }

@media (min-width: 992px) {
  .nav_jump > .menu_item {
    width: 7.69231%; } }

.city_list .nav_menu > .menu_item,
.city_menu .nav_menu > .menu_item {
  float: left;
  width: 49%; }
  .city_list .nav_menu > .menu_item:nth-child(odd),
  .city_menu .nav_menu > .menu_item:nth-child(odd) {
    clear: both; }
  .city_list .nav_menu > .menu_item:nth-child(even),
  .city_menu .nav_menu > .menu_item:nth-child(even) {
    float: right; }
  .city_list .nav_menu > .menu_item > .menu_link,
  .city_menu .nav_menu > .menu_item > .menu_link {
    font-size: 85%;
    border: 1px solid #1b75bc;
    background-color: #1867a6;
    color: #fff; }
    .city_list .nav_menu > .menu_item > .menu_link:hover,
    .city_menu .nav_menu > .menu_item > .menu_link:hover {
      background-color: #409be4; }
  .city_list .nav_menu > .menu_item > [href="#"],
  .city_menu .nav_menu > .menu_item > [href="#"] {
    border-color: #aaa;
    background-color: #ddd;
    color: #333; }
    .city_list .nav_menu > .menu_item > [href="#"]:hover,
    .city_menu .nav_menu > .menu_item > [href="#"]:hover {
      background-color: #ddd;
      cursor: default; }

.city_list {
  margin-bottom: 1em; }
  .city_list .nav_menu > .menu_item > .menu_link {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase; }
  @media (min-width: 992px) {
    .city_list .nav_menu > .menu_item {
      width: 32%;
      margin-right: 2%; }
      .city_list .nav_menu > .menu_item:nth-child(odd) {
        clear: none; }
      .city_list .nav_menu > .menu_item:nth-child(even) {
        float: left; }
      .city_list .nav_menu > .menu_item:nth-child(3n) {
        float: right;
        margin-right: 0; }
      .city_list .nav_menu > .menu_item:nth-child(3n-2) {
        clear: both; } }

@media (min-width: 768px) {
  .city_menu .nav_menu {
    text-align: center; }
  .city_menu .nav_menu > .menu_item {
    float: none;
    display: inline-block;
    width: auto; }
    .city_menu .nav_menu > .menu_item:nth-child(odd) {
      clear: none; }
    .city_menu .nav_menu > .menu_item:nth-child(even) {
      float: none; } }

.mobile_button {
  padding: 0 20px;
  background: #14426F; }
  .mobile_button .mobile_toggle {
    padding: 0.75em;
    background-color: #14426F;
    border: none;
    color: #fff; }
    .mobile_button .mobile_toggle:hover, .mobile_button .mobile_toggle:focus {
      outline: none;
      background-color: #35bf53;
      cursor: pointer; }
  .mobile_button .button-bars {
    display: inline-block;
    height: 100%;
    width: 1.5em;
    margin-right: 0.25em; }
  .mobile_button .icon-bar {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff; }
    .mobile_button .icon-bar:nth-child(2) {
      margin: 0.3em 0; }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #14426F;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .nav_menu > .menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .nav_mobile .nav_menu > .menu_item {
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.0325em; }
    .nav_mobile .nav_menu > .menu_item.active .menu_link {
      background-color: #fff;
      color: #35bf53; }
      .nav_mobile .nav_menu > .menu_item.active .menu_link:hover, .nav_mobile .nav_menu > .menu_item.active .menu_link:focus {
        outline: none;
        background-color: #fff;
        color: #35bf53; }
    .nav_mobile .nav_menu > .menu_item.open > .menu_link {
      background-color: rgba(255, 255, 255, 0.2); }
    .nav_mobile .nav_menu > .menu_item.open .dropdown_menu {
      background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item > .menu_link {
    padding: 12px 10px;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav_menu > .menu_item > .menu_link:hover, .nav_mobile .nav_menu > .menu_item > .menu_link:focus {
      outline: none;
      background-color: #35bf53;
      color: #fff; }
  .nav_mobile .dropdown_menu .menu_item {
    font-size: 0.875em; }
  .nav_mobile .dropdown_menu .menu_link {
    background-color: transparent;
    color: #fff; }
    .nav_mobile .dropdown_menu .menu_link:hover, .nav_mobile .dropdown_menu .menu_link:focus {
      outline: none;
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.navmenu {
  display: none;
  width: 290px;
  height: 100%;
  border-width: 1px;
  border-style: solid; }
  .navmenu.in {
    display: block; }

.navmenu-fixed-left {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: auto;
  border-width: 0 1px 0 0;
  bottom: 0;
  overflow-y: auto; }

.nav--pills .nav_menu > .menu_item > .menu_link {
  border-radius: 4px; }

.nav--pills .nav_menu > .open > .menu_link {
  border-radius: 4px 4px 0 0; }

.page-title {
  position: relative;
  z-index: 70000;
  margin-top: -15px;
  padding: 3em 0 2em;
  color: #fff;
  border-bottom: 5px solid #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
  .page-title h1 {
    margin: 0.5em 0 0;
    font-size: 1.5rem;
    line-height: 1em;
    text-align: center; }
    .page-title h1 small {
      display: block;
      margin: 0.5em 0 0;
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1em;
      text-transform: uppercase; }
  @media (min-width: 576px) {
    .page-title {
      margin-top: -20px; }
      .page-title h1 {
        font-size: 1.7rem; } }
  @media (min-width: 768px) {
    .page-title {
      margin-top: 0;
      padding: 5em 0 1.5em; }
      .page-title h1 {
        font-size: 2rem; } }
  @media (min-width: 992px) {
    .page-title {
      padding: 7em 0 1.5em; }
      .page-title h1 {
        text-align: left;
        font-size: 2.3rem; }
        .page-title h1 small {
          font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    .page-title h1 {
      font-size: 2.75rem; } }
  .post .page-title h1 {
    max-width: 730px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layoutajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/_/fonts/slick.eot");
  src: url("/_/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/_/fonts/slick.woff") format("woff"), url("/_/fonts/slick.ttf") format("truetype"), url("/_/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.copyright_data ul > li {
  line-height: 1.1em; }

.copyright_data a {
  color: #fff;
  font-weight: 600;
  text-decoration: none; }

@media (min-width: 576px) {
  .copyright_data ul > li {
    display: inline-block; }
    .copyright_data ul > li:not(:last-child) {
      margin-right: 0.75em; } }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin: 0 0 15px;
  padding: 15px;
  font-size: 85%;
  border: 1px solid #ddd;
  background-color: #fff; }
  .widget > *:last-child {
    margin-bottom: 0; }
  .widget.widget-default .widget-title {
    color: #333; }
  .widget.widget-text {
    border-color: #333; }
    .widget.widget-text .widget-title {
      background-color: #333; }
  .widget.widget-primary {
    border-color: #1b75bc; }
    .widget.widget-primary .widget-title {
      background-color: #1b75bc; }
  .widget.widget-secondary {
    border-color: #14426F; }
    .widget.widget-secondary .widget-title {
      background-color: #14426F; }
  .widget.widget-highlight {
    border-color: #ef4136; }
    .widget.widget-highlight .widget-title {
      background-color: #ef4136; }
  .widget.widget-link {
    border-color: #35bf53; }
    .widget.widget-link .widget-title {
      background-color: #35bf53; }
  .widget.widget-review {
    border-color: #fd7e14; }
    .widget.widget-review .widget-title {
      background-color: #fd7e14; }
  .widget.widget-success {
    border-color: #28a745; }
    .widget.widget-success .widget-title {
      background-color: #28a745; }
  .widget.widget-danger {
    border-color: #dc3545; }
    .widget.widget-danger .widget-title {
      background-color: #dc3545; }
  .widget.widget-info {
    border-color: #6610f2; }
    .widget.widget-info .widget-title {
      background-color: #6610f2; }
  .widget.widget-warning {
    border-color: #ffc107; }
    .widget.widget-warning .widget-title {
      background-color: #ffc107; }

.widget-title:not(.plain) {
  margin: -15px -15px 15px;
  padding: 12px 15px;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #ddd;
  color: #fff; }

.widget-title.plain {
  margin: 0 0 5px; }

.widget_plain {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent; }
  .widget_plain .widget-title {
    margin: 0 0 15px;
    padding: 0px;
    background-color: transparent;
    color: inherit; }

@media (min-width: 768px) and (max-width: 991px) {
  .widget-rwreviews {
    overflow: hidden; }
    .widget-rwreviews p {
      float: left;
      width: 49%; }
      .widget-rwreviews p:nth-child(odd) {
        clear: both; }
      .widget-rwreviews p:nth-child(even) {
        float: right; } }

.widget-pleads {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-width: 3px;
  border-color: #07b; }
  .widget-pleads .widget-title {
    background-color: #07b; }
  .widget-pleads img {
    max-width: 240px;
    margin: 0 auto 0.5em; }

.block-main {
  padding: 1.5em 0;
  clear: both; }
  .block-main .block_title {
    margin-top: 0; }

.block.quote {
  background-color: #ef4136; }
  .block.quote .block_title {
    font-weight: 800;
    color: #fff; }
    .block.quote .block_title small {
      display: block;
      font-size: 72.5%;
      font-weight: 400;
      letter-spacing: 0.075em; }

.jumbo {
  padding: 3em 0;
  background: #14426F;
  color: #fff;
  text-align: center; }

.marketing_title h1 {
  margin: 0; }
  .marketing_title h1:before {
    content: "Need a "; }
  .marketing_title h1:after {
    content: "?"; }

.marketing_title p {
  margin: 1em 0 1em;
  font-size: 1.3rem;
  font-weight: 600; }

@media (min-width: 768px) {
  .marketing_title h1 {
    font-size: 2.1rem; }
  .marketing_title p {
    font-size: 1.6rem; } }

@media (min-width: 992px) {
  .marketing_title h1 {
    font-size: 2.3rem; }
  .marketing_title p {
    font-size: 1.8rem; } }

.marketing_text ul {
  margin: 0;
  font-size: 1.2rem; }
  .marketing_text ul li {
    margin-bottom: 0.25em !important; }
    .marketing_text ul li i {
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.125em;
      vertical-align: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      background-image: url("/_/images/icons/cm_accent.svg"); }

@media (min-width: 768px) {
  .marketing_text ul {
    font-size: 1.3rem; }
    .marketing_text ul li {
      margin-bottom: 0.5em !important; } }

@media (min-width: 992px) {
  .marketing_text ul {
    font-size: 1.5rem; } }

.marketing_convert .btn {
  margin: 1em auto 0;
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: -0.0125em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }

.page_contractor .content {
  border-top: 6px solid #35bf53; }

.company_block {
  text-align: center; }
  @media (min-width: 576px) {
    .company_block .gallery {
      overflow: hidden;
      margin-bottom: 1em; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .company_block .company_logo {
      float: left;
      width: 49%;
      margin-left: 0; }
    .company_block .company_call,
    .company_block .company_address {
      float: right;
      width: 49%;
      text-align: right; }
    .company_block .list_company {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 0.5em; } }
  @media (min-width: 992px) {
    .company_block {
      text-align: left; }
      .company_block .gallery > img {
        margin-bottom: 0.5em; }
      .company_block .list_company > li {
        display: block;
        width: 100%; } }

.company_logo {
  margin: 0 auto 0.5em; }

.company_call {
  line-height: 1.1em; }

.company_call .phone {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: -0.025em;
  color: #1b75bc; }
  @media (min-width: 992px) {
    .company_call .phone {
      font-size: 42px;
      letter-spacing: -0.05em; } }

.call_emergency {
  display: inline-block;
  margin-top: 0.05em;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: #dc3545; }

.company_address {
  font-size: 85%;
  line-height: 1.25em; }
  .company_address .address_title {
    font-size: 115%; }
  @media (min-width: 992px) {
    .company_address {
      padding: 0.5em 0.75em;
      border-left: 5px solid #ccc; } }

.list_company {
  clear: both;
  font-weight: 600; }
  .list_company li {
    display: inline-block;
    padding-left: 1.5em;
    line-height: 1.25em; }
    .list_company li:not(:last-child) {
      margin-right: 0.5em; }

.block_services {
  padding: 0 0 2em;
  text-align: center;
  background-color: #fff; }
  .block_services .services_title {
    margin: 0 0 1em;
    padding: 1em 0;
    background-color: #35bf53;
    color: #fff; }
    .block_services .services_title .block_title {
      margin: 0;
      font-style: italic;
      font-weight: 800;
      text-transform: uppercase; }
  .block_services img {
    max-width: 64px;
    margin: 0 auto 1em; }
  .block_services .icon_list {
    overflow: hidden; }
    .block_services .icon_list li {
      display: inline-block;
      padding: 0.5em;
      font-size: 14px;
      font-weight: 600;
      line-height: 1em;
      letter-spacing: -0.03em;
      text-align: center;
      text-transform: uppercase; }
  .block_services hr {
    margin-bottom: 1.5em; }
  .block_services .service_list {
    font-size: 1rem;
    font-style: italic;
    font-weight: 800;
    line-height: 1em; }
    .block_services .service_list span {
      display: inline-block;
      padding: 0.5em;
      background-color: #14426F;
      color: #fff; }
    .block_services .service_list li:last-child span {
      background-color: #35bf53; }
  .block_services p {
    margin: 0 auto 1em; }
    .block_services p:last-of-type {
      margin-bottom: 0; }
  @media (min-width: 576px) {
    .block_services .icon_list li {
      display: block;
      float: left;
      width: 20%; } }
  @media (min-width: 768px) {
    .block_services .service_list {
      font-size: 1.1rem; } }
  @media (min-width: 992px) {
    .block_services .icon_list li {
      font-size: 15px; }
    .block_services .service_list {
      font-size: 1.2rem; } }
  @media (min-width: 1200px) {
    .block_services .icon_list li {
      font-size: 16px; }
    .block_services .service_list {
      margin-bottom: 0.75em;
      font-size: 1.3rem; } }

.slider {
  padding: 0;
  border: 10px solid #fff;
  border-left-width: 5px;
  border-right-width: 5px; }
  .slider img {
    border: 5px solid #fff;
    border-top: none;
    border-bottom: none; }

.welcome {
  padding: 2em 0 1em; }

.site_info {
  border-top: 5px solid #fff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #1b75bc;
  color: #fff;
  font-size: 0.9em; }

#top {
  outline: none !important; }

.page_front .content {
  padding: 2em 0; }

.page_glossary hr,
.page_glossary hr:focus {
  margin: 2em 0;
  border: 0;
  height: 2px;
  outline: none !important;
  background-image: linear-gradient(to right, rgba(53, 191, 83, 0) 0%, rgba(53, 191, 83, 0.9) 35%, rgba(53, 191, 83, 0.9) 65%, rgba(53, 191, 83, 0)); }

.page_glossary [href="#top"] {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.95rem;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #c91b10;
  border-radius: 4px;
  background-color: #ef4136;
  color: #fff; }
  .page_glossary [href="#top"]:hover {
    background-color: #f5847d; }

.page_glossary h3 {
  padding: 0.325em 0.5em;
  font-weight: 800;
  background: linear-gradient(to right, #1b75bc 0px, #1b75bc 46px, transparent 46px);
  color: #fff; }
  @media (min-width: 768px) {
    .page_glossary h3 {
      padding: 0.5em;
      background: linear-gradient(to right, #1b75bc, #1b75bc 10%, transparent 60%); } }

.sidebar [class*="img_group"] > img, .sidebar [class*="img_group"] > a {
  display: block;
  max-width: 300px;
  margin: 0 auto 15px; }

.sidebar .pgroup a {
  padding: 15px;
  background-color: #fff; }

@media (min-width: 576px) and (max-width: 767px) {
  .sidebar .img_group--2 {
    overflow: hidden;
    margin-bottom: 15px; }
    .sidebar .img_group--2 > img, .sidebar .img_group--2 > a {
      display: block;
      float: left;
      width: 49.5%;
      margin: 0; }
      .sidebar .img_group--2 > img:nth-child(even), .sidebar .img_group--2 > a:nth-child(even) {
        float: right; }
  .sidebar .img_group--3 {
    overflow: hidden;
    margin-bottom: 15px; }
    .sidebar .img_group--3 > img, .sidebar .img_group--3 > a {
      float: left;
      width: 32.5%;
      margin-right: 1.25%; }
      .sidebar .img_group--3 > img:nth-child(3n), .sidebar .img_group--3 > a:nth-child(3n) {
        margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar .img_group--2 {
    overflow: hidden;
    margin-bottom: 15px; }
    .sidebar .img_group--2 > img, .sidebar .img_group--2 > a {
      display: block;
      float: left;
      width: 49.5%;
      margin: 0; }
      .sidebar .img_group--2 > img:nth-child(even), .sidebar .img_group--2 > a:nth-child(even) {
        float: right; }
  .sidebar .img_group--3 {
    overflow: hidden;
    margin-bottom: 15px; }
    .sidebar .img_group--3 > img, .sidebar .img_group--3 > a {
      float: left;
      width: 32.5%;
      margin-right: 1.25%; }
      .sidebar .img_group--3 > img:nth-child(3n), .sidebar .img_group--3 > a:nth-child(3n) {
        margin-right: 0; } }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  .sidebar .form_submit input {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .sidebar .form_spam .g-recaptcha {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
      position: absolute;
      top: 0;
      left: -8px; } }

.sidebar > * {
  margin-bottom: 15px; }
