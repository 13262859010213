.coverage {

	padding: 0.5em 0.5em 0.75em;
	border: 1px solid #ddd;
	background-color: $white;

	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

}

	.coverage_title {
		margin: 1em 0.5em 0.5em;
		font-size: 17px;
		font-weight: 800;
		text-transform: uppercase;
	}

		.coverage_city {
			margin: 0 0.5em;
			font-size: 14px;
			line-height: 1.25em;
		}

			.coverage_city+.coverage_city {
				margin-top: 0.5em;
			}
