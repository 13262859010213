.btn_3d {
	
	padding: 12px 1em;
	border-width: 1px;
	border-bottom-width: 6px;

	&:hover {
		padding: 15px 1em 14px;
		border-bottom-width: 1px;
	}

}