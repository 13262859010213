.page-title {
	position: relative;
	z-index: 70000;
	margin-top: -15px;
	padding: 3em 0 2em;
	color: $white;
	border-bottom: 5px solid $white;
	text-shadow: 0 1px 2px rgba(0,0,0,0.4);
	@extend .gdfancybg--accent;

	h1 {
		margin: 0.5em 0 0;
		font-size: 1.5rem;
		line-height: 1em;
		text-align: center;
		small {
			display: block;
			margin: 0.5em 0 0;
			font-size: 1.1rem;
			font-weight: 600;
			line-height: 1em;
			text-transform: uppercase;
		}
	}
	
	@include media-breakpoint-up(sm) {
		margin-top: -20px;
		h1 { font-size: 1.7rem; }
	}

	@include media-breakpoint-up(md) {
		margin-top: 0;
		padding: 5em 0 1.5em;
		h1 { font-size: 2rem; }
	}
	
	@include media-breakpoint-up(lg) {
		padding: 7em 0 1.5em;
		h1 {
			text-align: left;
			font-size: 2.3rem;
			small { font-size: 1.3rem; }
		}
	}

	@include media-breakpoint-up(xl) {
		h1 { font-size: 2.75rem; }
	}

	.post & {
		h1 { max-width: 730px; }
	}

}

.page_contractor .page-title {
	//margin-top: 0;
	//@include media-breakpoint-up(sm) { margin-top: 0; }
}
