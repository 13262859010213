//.title_branding {}

	.branding_logo {

		display: block;
		width: 100%;
		margin: 0 auto;
		line-height: 0;

		svg { margin: 0 !important; }

		svg .mark-border { fill:#1B75BC }
		
		svg .mark-bg {
			fill:#14426F;
			@include transition(all ease-in-out 400ms);
		}
		
		svg .wrench-bg { fill:#1B75BC }
		svg .wrench-outline { fill:#fff }
		svg .plumbers { fill:#fff }
		svg .net { fill:#14426F }
		svg .bg { fill:#1B75BC }

		&:hover {
			svg .mark-bg {
				fill: $color-accent;
				@include transition(all ease-in-out 200ms);
			}
		}

	}