// ===========================================
// BOOTSTRAP'S GRID
// ===========================================

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .wrap {
		padding-right: 0;
		padding-left: 0;
	}
}


// ===========================================
// BACE GRID
// ===========================================

html {
	@include prefix('box-sizing', 'border-box', webkit moz ms o);
    -ms-overflow-style: scrollbar;
}

	*,
	*:before,
	*:after {

		@include prefix('box-sizing', 'inherit', webkit moz ms o)
		
	}

	.block { padding: 20px 0; }

		.block--center {
			.row { justify-content: center; }
		}

	.wrapper {
		@include make-row();
		justify-content: center;
	}

	.wrap {
		
		@include make-col-ready();

		&[class*="col"] { padding: 0.5em; }

		&.col-12 { @include make-col(12) }
		&.col-11 { @include make-col(11) }
		&.col-10 { @include make-col(10) }
		&.col-9 { @include make-col(9) }
		&.col-8 { @include make-col(8) }
		&.col-7 { @include make-col(7) }
		&.col-6 { @include make-col(6) }
		&.col-5 { @include make-col(5) }
		&.col-4 { @include make-col(4) }
		&.col-3 { @include make-col(3) }
		&.col-2 { @include make-col(2) }
		&.col-1 { @include make-col(1) }


		@include media-breakpoint-up(sm) {
			&.col-sm-12 { @include make-col(12) }
			&.col-sm-11 { @include make-col(11) }
			&.col-sm-10 { @include make-col(10) }
			&.col-sm-9 { @include make-col(9) }
			&.col-sm-8 { @include make-col(8) }
			&.col-sm-7 { @include make-col(7) }
			&.col-sm-6 { @include make-col(6) }
			&.col-sm-5 { @include make-col(5) }
			&.col-sm-4 { @include make-col(4) }
			&.col-sm-3 { @include make-col(3) }
			&.col-sm-2 { @include make-col(2) }
			&.col-sm-1 { @include make-col(1) }
		}

		@include media-breakpoint-up(md) {
			//&.col-md-break { float: none; width: percentage(12/12); }
			&.col-md-12 { @include make-col(12) }
			&.col-md-11 { @include make-col(11) }
			&.col-md-10 { @include make-col(10) }
			&.col-md-9 { @include make-col(9) }
			&.col-md-8 { @include make-col(8) }
			&.col-md-7 { @include make-col(7) }
			&.col-md-6 { @include make-col(6) }
			&.col-md-5 { @include make-col(5) }
			&.col-md-4 { @include make-col(4) }
			&.col-md-3 { @include make-col(3) }
			&.col-md-2 { @include make-col(2) }
			&.col-md-1 { @include make-col(1) }
		}

		@include media-breakpoint-up(lg) {
			&.col-lg-12 { @include make-col(12) }
			&.col-lg-11 { @include make-col(11) }
			&.col-lg-10 { @include make-col(10) }
			&.col-lg-9 { @include make-col(9) }
			&.col-lg-8 { @include make-col(8) }
			&.col-lg-7 { @include make-col(7) }
			&.col-lg-6 { @include make-col(6) }
			&.col-lg-5 { @include make-col(5) }
			&.col-lg-4 { @include make-col(4) }
			&.col-lg-3 { @include make-col(3) }
			&.col-lg-2 { @include make-col(2) }
			&.col-lg-1 { @include make-col(1) }
		}	

		@include media-breakpoint-up(xl) {
			&.col-xl-12 { @include make-col(12) }
			&.col-xl-11 { @include make-col(11) }
			&.col-xl-10 { @include make-col(10) }
			&.col-xl-9 { @include make-col(9) }
			&.col-xl-8 { @include make-col(8) }
			&.col-xl-7 { @include make-col(7) }
			&.col-xl-6 { @include make-col(6) }
			&.col-xl-5 { @include make-col(5) }
			&.col-xl-4 { @include make-col(4) }
			&.col-xl-3 { @include make-col(3) }
			&.col-xl-2 { @include make-col(2) }
			&.col-xl-1 { @include make-col(1) }
		}

	}

	// ==================================================
	// BLOCKS
	// ==================================================

	.banner {
		position: relative;
		z-index: 99000;
	}

	.jumbo {
		position: relative;
		z-index: 70000;
		@include media-breakpoint-up(md) {
			.wrapper .wrap {
				@include make-col(6);
			}
		}
		@include media-breakpoint-up(lg) {
			.wrapper .wrap {
				@include make-col(5);
			}			
		}
		@include media-breakpoint-up(xl) {
			.wrapper .wrap {
				@include make-col(4);
			}			
		}
	}

	.content {
		@include media-breakpoint-up(lg) {
			.main { @include make-col(8) }
			.sidebar { @include make-col(4) }
		}
	}

	.full_width,
	.contractor_list {

		@include media-breakpoint-up(lg) {
			.content, .page-title {
				.row { justify-content: center; }
			}
			.content .main,
			.page-title .wrap {
				@include make-col(10)
			}
		}

	}

/* 	.page_contractor {

		.page-title,
		.welcome {
			.row { justify-content: center; }
			@include media-breakpoint-up(lg) { .wrap { @include make-col(11) } }			
			@include media-breakpoint-up(xl) { .wrap { @include make-col(11) } }			
		}

		.content {
			.row { justify-content: center; }
			@include media-breakpoint-up(lg) {
				.main { @include make-col(7); }
				.sidebar { @include make-col(4); }
			}
 			@include media-breakpoint-up(xl) {
				.main { @include make-col(6); }
				.sidebar { @include make-col(4); }
			}
		}

	} */