.block_services {

	padding: 0 0 2em;
	text-align: center;
	background-color: $white;

	.services_title {
		margin: 0 0 1em;
		padding: 1em 0;
		//border-top: 5px solid $white;
		background-color: $color-accent;
		color: $white;
		.block_title {
			margin: 0;
			font-style: italic;
			font-weight: 800;
			text-transform: uppercase;
		}
	}

	img {
		max-width: 64px;
		margin: 0 auto 1em;
	}

	.icon_list {
		overflow: hidden;
		li {
			display: inline-block;
			padding: 0.5em;
			font-size: 14px;
			font-weight: 600;
			line-height: 1em;
			letter-spacing: -0.03em;
			text-align: center;
			text-transform: uppercase;
		}
	}

	hr { margin-bottom: 1.5em }

	.service_list {
		font-size: 1rem;
		font-style: italic;
		font-weight: 800;
		line-height: 1em;
		span {
			display: inline-block;
			padding: 0.5em;
			background-color: theme(secondary, base);
			color: $white;
		}
		li:last-child span { background-color: theme(accent, base) }
	}

	p {
		margin: 0 auto 1em;
		&:last-of-type { margin-bottom: 0 }
	}

	@include media-breakpoint-up(sm) {
		.icon_list li {
			display: block;
			float: left;
			width: 20%;
		}		
	}

	@include media-breakpoint-up(md) {
		.service_list { font-size: 1.1rem }
	}

	@include media-breakpoint-up(lg) {
		.icon_list li { font-size: 15px }
		.service_list { font-size: 1.2rem }
	}

	@include media-breakpoint-up(xl) {
		.icon_list li { font-size: 16px }
		.service_list { margin-bottom: 0.75em; font-size: 1.3rem }
	}

}
