.banner_menu {

	padding: 35px 0 0;
	background-color: $color-secondary;
	border-bottom: 5px solid $white;

	.nav_menu {

		text-align: center;

		> .menu_item {

			display: inline-block;

			> .menu_link {
				&:active, &:link, &:visited, &:hover {
					padding: 15px;
					font-size: 14px;
					font-weight: 800;
					text-transform: uppercase;
					text-align: center;					
					background-color: transparent;
					color: $white;			
				}
				&:hover {
					background-color: $color-accent;
					color: $white;
				}
			}

			&.active > .menu_link {
				background-color: $white;
				color: $color-secondary;
			}

	 		a[href="#"]:hover {
				background-color: transparent !important;
				border-color: transparent !important;
				color: $white !important;
				cursor: default !important;
			}
			
		}


	}

	@include media-breakpoint-up(lg) {
		.nav_menu > .menu_item {
			display: block;
			float: left;
			width: percentage(1/7);
			//&:not(:last-child) { border-right: 1px solid black; }
			//&:not(:first-child) { border-left: 1px solid white; }
		}
	}

}